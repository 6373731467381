import React from 'react';
import styled from 'styled-components';

import themeDefaultProps from '../../themes/dark';
// import useSiteMetadata from '../lib/useSiteMetadata';

const FooterWrapper = styled.div`
  font-family: ${(props) => props.theme.typography.type.primary};
  color: ${(props) => props.theme.color.primary};
`;
FooterWrapper.defaultProps = {
  theme: themeDefaultProps,
};
const Subject = styled.div`
  text-align: center;
`;
Subject.defaultProps = {
  theme: themeDefaultProps,
};

export default function Footer({ ...props }) {
  // const { urls = {} } = useSiteMetadata();
  return (
    <FooterWrapper {...props}>
      <footer>
        <Subject>
          ©
          {new Date().getFullYear()}
          {' '}
          | leonarpv
        </Subject>
      </footer>
    </FooterWrapper>
  );
}

Footer.propTypes = {};
