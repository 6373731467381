import React from 'react';
import styled, { css, createGlobalStyle } from 'styled-components';

import themeDefaultProps from '../../themes/dark';

const GlobalStyle = createGlobalStyle`
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
html {
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  -webkit-tap-highlight-color: transparent;
}
body {
  background-color: ${props => props.theme.background.primary};
  font-family: ${props => props.theme.typography.type.secondary};
  width: 100vw;
  height: 100vh;
  display: block;
  transition: transform .2s ease-out;
}
main{
    width: 100%;
    margin: auto;
    max-width: 792px;
}

`;
GlobalStyle.defaultProps = {
  theme: themeDefaultProps,
};
export default function Global() {
  return <GlobalStyle />;
}
