import { useStaticQuery, graphql } from 'gatsby';

const useSiteMetadata = () => {
  const result = useStaticQuery(graphql`
    {
      site {
        siteMetadata {
          title
          author
          description
        }
      }
    }
  `);

  return result.site.siteMetadata;
};

export default useSiteMetadata;
