import React from 'react';
import styled from 'styled-components';
import themeDefaultProps from '../../themes/dark';
import { useSiteState, useSiteDispatch } from '../../context';

const HeaderWrapper = styled.div`
  align-self: auto;
  flex-direction: row;
  justify-content: space-between;
`;
HeaderWrapper.defaultProps = {
  theme: themeDefaultProps,
};

const Header = ({ ...props }) =>
//  const state = useSiteState();
//  const dispatch = useSiteDispatch();

// const { darkMode } = state;
  <HeaderWrapper {...props} />;
export default Header;
