import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import styled from 'styled-components';
import useSiteMetadata from '../lib/useSiteMetadata';
import Header from './Header';
import Footer from './Footer';
import Global from './Global';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 100vh;
`;
export default function PageLayout({ children, ...props }) {
  const {
    title,
    author,
    description: content,
    googleSiteVerification,
  } = useSiteMetadata();
  const { tags } = props;
  const keywords = tags && tags.toString();

  return (
    <>
      <Global />
      <Helmet
        meta={[
          {
            title,
            name: 'keywords',
            author,
            content,
          },
        ]}
      >
        <meta name="keywords" content={keywords} />
        <html lang="en" />
        <link
          rel="shortcut icon"
          type="image/png"
          href="/images/leonarpv-icon.png"
          sizes="16x16 32x32 64x64"
        />
        <meta
          name="google-site-verification"
          content={googleSiteVerification}
        />
      </Helmet>
      <Wrapper>
        <Header />
        {children}
        <Footer />
      </Wrapper>
    </>
  );
}
/*
  <Header />
        <Container children={children} />
        <Footer />
*/
PageLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

PageLayout.defaultProps = {};
